.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}
.popup {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  margin-top: 300px;
  padding: 10px;
  margin-bottom: 1px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  min-width: 222px;
  position: relative;
  /* transition: all 5s ease-in-out; */
  z-index: 100;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  cursor: pointer;
  color: #000;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}
