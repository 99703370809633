.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox::after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.lock-image {
  margin-top: 40px;
  /* margin-left: 42%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .lock-image img {
    width: 20%;
  }

  .blockWebsitesTitle {
    font-size: 2rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .lock-image img {
    width: 15%;
  }

  .blockWebsitesTitle {
    font-size: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .lock-image img {
    width: 12%;
  }
}

@media screen and (min-width: 1025px) {
  .lock-image img {
    width: 10%;
  }
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

.cart-image img {
  margin-top: 40px;
}

@media screen and (max-width: 480px) {
  .cart-image img {
    width: 30%;
  }
  .selectYourPlanTitle {
    font-size: 2rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .cart-image img {
    width: 20%;
  }
  .selectYourPlanTitle {
    font-size: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .cart-image img {
    width: 20%;
  }
}

@media screen and (min-width: 1025px) {
  .cart-image img {
    width: 20%;
  }
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
  text-align: center;
}

strong {
  font-weight: 400;
}

.body {
  margin-right: 0px;
  margin-left: 0px;
  border: 1px none #000;
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #ddd;
  font-size: 24px;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #262626;
  font-size: 24px;
  text-align: center;
}

.columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.text-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #4d4d4d;
  text-align: center;
}

.text-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family:
    Proxima nova alt,
    sans-serif;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: 700;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.bold-text {
  font-family:
    Proxima nova alt,
    sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: right;
}

.bold-text-2 {
  color: #b50000;
}

.text-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.bold-text-3 {
  font-size: 14px;
  font-weight: 400;
}

.logo-and-menu-section {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
  background-color: #fff;
}

.link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #4d4d4d;
}

.column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.text-block-5 {
  font-size: 18px;
  text-align: center;
}

.text-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 18px;
}

.columns-2 {
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.column-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 18px;
}

.heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 28px;
  font-weight: 400;
  text-align: left;
}

.text-block-7 {
  font-size: 14px;
}

.text-block-8 {
  font-size: 14px;
  text-align: left;
}

.image {
  width: 100px;
  min-width: 100px;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  background-color: transparent;
}

.nav-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: block;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.nav-logo {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  font-size: 18px;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.navbar {
  background-color: transparent;
}

.subscription-section {
  color: #262626;
  text-align: center;
  background-color: #ecedf1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 0;
  display: block;
  /*updated*/
}

.text-block-9 {
  font-family:
    Proxima nova alt,
    sans-serif;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 700;
}

.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-block-10 {
  display: block;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.bold-text-53 {
  color: #262626;
  font-weight: 400;
}

.video-columns {
  display: block;
  margin-top: 0px;
  border-radius: 10px;
}

.column-37 {
  padding: 20px;
  border: 10px none #000;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.video-2 {
  width: 50%;
  height: 60%;
  max-height: none;
  max-width: none;
  min-width: auto;
  object-fit: contain;
  background-color: #fff;
  border: 2px #000;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.heading-3 {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-right: 10px;
  padding-left: 10px;
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.heading-4 {
  color: #262626;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
}

.grid {
  margin-top: 40px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 0px;
  grid-row-gap: 22px;
  -ms-grid-columns: 0.5fr 0.25fr 0.25fr 0.5fr;
  grid-template-columns: 0.5fr 0.25fr 0.25fr 0.5fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: Proximanova, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}

.image-2 {
  width: 75px;
  height: 75px;
  border-style: none;
  border-width: 3px;
  border-color: #00b050;
  border-radius: 50px;
  text-align: center;
  -o-object-fit: contain;
  object-fit: contain;
}

.image-10 {
  width: 75px;
  height: 75px;
  -o-object-fit: contain;
  object-fit: contain;
}

.text-block-11 {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 16px;
}

.image-3 {
  display: block;
  width: 75px;
  height: 75px;
  -o-object-fit: contain;
  object-fit: contain;
}

.text-block-101 {
  color: #262626;
  text-align: right;
}

.text-block-47 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.text-block-102 {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 16px;
  text-align: right;
}

.column-38 {
  background-image: url("../images/grey-round-square-button.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
}

.text-block-103 {
  font-size: 18px;
  text-align: center;
}

.columns-22 {
  margin-top: 40px;
  font-size: 2px;
}

.grid-2 {
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border: 1px none #000;
  border-radius: 20px;
  text-align: center;
}

.text-block-104 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 20px;
  background-color: #fff;
  background-image: url("../images/No-Email.png");
  background-position: 50% 50%;
  background-size: 200px;
  background-repeat: no-repeat;
  box-shadow: 1px 1px 3px 3px rgba(39, 38, 38, 0.12);
  font-size: 18px;
  text-align: center;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.heading-5 {
  margin-top: 0px;
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.email-sign-column {
  padding: 10px 0px;
  border-radius: 0px;
  background-color: transparent;
  text-align: center;
}

.no-risk-trial-row-1-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.image-11 {
  display: inline-block;
  width: 75px;
  height: 75px;
  min-height: 75px;
  min-width: 75px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-105 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  text-align: center;
}

.credit-card-columns {
  padding: 10px 0px;
  border-radius: 0px;
  background-color: transparent;
  text-align: center;
}

.text-block-106 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  text-align: center;
}

.column-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 3px 2px #858181;
}

.column-42 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 3px 2px #858181;
}

.no-risk-trial-row-2-columns {
  margin-right: 40px;
  margin-left: 40px;
}

.no-software-column {
  padding: 10px 0px;
}

.no-devices-column {
  padding: 10px 0px;
}

.column-43 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 3px 2px #858181;
  text-align: center;
}

.image-12 {
  width: 75px;
  height: 75px;
  max-height: none;
  min-height: 75px;
  min-width: 75px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-107 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  text-align: center;
}

.image-13 {
  width: 75px;
  height: 75px;
  min-height: 75px;
  min-width: 75px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.video-section {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #e9e9e9;
}

.column-44 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 3px 2px #858181;
  text-align: center;
}

.text-block-108 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
  text-align: center;
}

.text-block-109 {
  margin-top: 0px;
  color: #4d4d4d;
  font-size: 14px;
  text-align: center;
}

.section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  text-align: center;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  min-height: auto;
  min-width: auto;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 2px 3px 2px #cecbcb;
  color: #b50000;
  font-size: 18px;
}

.button:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.section-3 {
  border-top: 1px solid #000;
  background-color: #fff;
  outline-color: #262626;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
  text-align: center;
}

.image-14 {
  margin-right: 100px;
  margin-left: 100px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.image-15 {
  max-width: 35%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.column-45 {
  border-top: 1px none #000;
}

.column-46 {
  display: block;
  padding: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
  color: transparent;
}

.text-block-110 {
  margin-right: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-right: 0px;
  color: #b50000;
  font-size: 18px;
  text-align: center;
}

.image-16 {
  width: 75px;
  height: 75px;
  min-height: 75px;
  min-width: 75px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.columns-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-47 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 0px;
  background-color: transparent;
}

.bold-text-54 {
  color: #b50000;
  font-weight: 400;
}

.body-2 {
  background-color: #ecedf1;
  box-shadow: 2px 2px 3px 2px #000;
  font-family: Proximanova, sans-serif;
  color: #262626;
}

.column-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Proximanova, sans-serif;
  font-weight: 400;
}

.bold-text-60 {
  font-family: Proximanova, sans-serif;
  color: #b50000;
  font-weight: 400;
}

.columns-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  text-align: center;
}

.column-48 {
  top: 140px;
  font-family: Proximanova, sans-serif;
}

.link-3 {
  background-color: transparent;
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.checkbox-label {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 16px;
}

.checkbox {
  border-style: solid;
  border-width: 1px;
  border-color: #b50000;
  border-radius: 0px;
  background-color: #fff;
}

.column-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-weight: 400;
}

.text-block-111 {
  background-color: transparent;
  font-family: "Proxima nova alt", sans-serif;
  color: #b50000;
  font-size: 18px;
  text-align: center;
}

.section-14 {
  background-color: transparent;
}

.div-block {
  background-color: transparent;
  text-align: center;
}

.column-49 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: #fff;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 2px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 16px;
}

.button-2:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.body-3 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
}

.section-22 {
  background-color: transparent;
}

.radio-button-field {
  background-color: transparent;
  font-family: Proximanova, sans-serif;
  color: #262626;
  text-align: center;
}

.column-50 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.bold-text-24 {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-weight: 400;
}

.radio-button-2 {
  border-color: #b50000;
}

.radio-button-label {
  font-family: Proximanova, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.text-block-112 {
  padding-right: 20px;
  padding-left: 20px;
  background-color: transparent;
  font-family:
    Proxima nova alt,
    sans-serif;
  color: #b50000;
  font-size: 18px;
  text-align: center;
}

.radio-button {
  border-style: solid;
  border-color: #b50000;
}

.section-23 {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  text-align: center;
}

.button-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
}

.button-3:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.div-block-2 {
  text-align: center;
}

.column-51 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.columns-25 {
  padding-right: 20px;
  padding-left: 20px;
}

.body-4 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #262626;
}

.text-block-113 {
  background-color: transparent;
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.section-16 {
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  display: block;
  position: static;
}

.video {
  position: relative;
  display: block;
  width: auto;
  height: 350px;
  border: 2px none #000;
  border-radius: 10px;
}

.text-block-59 {
  min-width: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  font-size: 16px;
  text-align: center;
}

.link-9 {
  color: #b50000;
  background-color: rgba(0, 0, 0, 0);
}

.project-name {
  color: #262626;
}

.tab-link-linksys {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

/* .tabs-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
  font-family: Proximanova, sans-serif;
  color: transparent;
  font-size: 16px;
} */

.tabs-2 {
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border: 2px #000;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Proximanova, sans-serif;
  font-size: 16px;
  display: flex;
}

.text-block-36,
.tab-pane-other {
  color: #262626;
}

.tab-link-netgear {
  color: #262626;
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.text-block-52,
.text-span {
  color: #262626;
}

.tab-pane-netgear {
  color: #262626;
  padding: 20px;
  font-size: 16px;
}

.tab-link-verizon {
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-linksys.w--current {
  background-color: #fc9c02;
}

.tab-link-netgear.w--current {
  background-color: #fc9c02;
}

.tab-link-verizon.w--current {
  background-color: #fc9c02;
  border: 1px #000;
  border-radius: 10px;
}

.tab-link-comcast-xfinity.w--current {
  background-color: #fc9c02;
}

.tab-link-tplink.w--current {
  background-color: #fc9c02;
}

.tab-link-asus.w--current {
  background-color: #fc9c02;
}

.text-block-41 {
  text-align: left;
  font-size: 16px;
}

.text-block-114 {
  min-width: 16px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  color: #262626;
  font-size: 18px;
  -webkit-column-count: auto;
  column-count: auto;
}

.tab-pane-comcast-xfinity {
  color: #262626;
  border: 1px #000;
  border-radius: 10px;
  padding: 20px;
}

.text-block-40 {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.text-block-37 {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.tab-pane-verizon {
  color: #262626;
  padding: 20px;
}

.tab-pane-linksys {
  color: #262626;
  text-align: left;
  padding: 20px;
  font-size: 16px;
}

.tab-link-comcast-xfinity {
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tabs-menu-2 {
  color: #fff;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 10px;
  font-family: Proximanova, sans-serif;
}

.tab-link-tplink {
  color: #262626;
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -3px 0 1px #fff,
    2px 2px 1px #000;
}

.columns-9 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #262626;
  font-size: 16px;
}

.columns-8 {
  color: #262626;
  font-size: 16px;
}

.text-block-42 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 26px;
}

.text-block-42-new {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 18px;
}

.router-instructions-block {
  color: #262626;
  text-align: left;
  margin-top: 0;
}

.columns-4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.tab-link-asus {
  color: #262626;
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tabs-content-2 {
  color: #262626;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  display: inline-block;
}

.columns-7 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tab-link-other {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #dadde0;
  border: 3px #00b050;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-other.w--current {
  background-color: #fc9c02;
  border-style: none;
}

.hero-heading-center {
  background-color: #fff;
  border-bottom: 1px solid #e4ebf3;
  border-radius: 10px;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 60px 30px;
  position: relative;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.columns-6 {
  margin-top: 10px;
}

.bold-text-64 {
  color: #262626;
  font-size: 18px;
  font-weight: 400;
}

.section-24 {
  display: block;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  text-align: center;
  white-space: normal;
}

.bold-text-65 {
  color: #b50000;
  font-weight: 400;
}

.button-section {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.button-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  margin-top: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 2px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 16px;
  text-align: center;
}

.button-4:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.columns-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-52 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: #fff;
}

.heading-6 {
  margin-top: 0px;
  color: #4d4d4d;
  font-weight: 400;
}

.bold-text-66 {
  color: #b50000;
  font-weight: 400;
}

.heading-7 {
  margin-top: 0px;
  color: #4d4d4d;
  font-weight: 400;
}

.floating-button {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  background-color: #fc9c02;
  color: #fff;
  border-radius: 50px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.75em;
  text-decoration: none;
  box-shadow: 2px 2px 3px #999;
}

.heading-8 {
  margin-top: 0px;
  color: #4d4d4d;
  font-weight: 400;
}

.text-block-115 {
  text-align: left;
  margin-top: 10px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
}

.columns-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-53 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
}

.column-54 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
}

.column-55 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 3px 2px #cecbcb;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  height: 100%;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  height: 100%;
  min-width: 75%;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.bold-text-67 {
  color: #4d4d4d;
  font-weight: 400;
}

.columns-28 {
  display: block;
}

.grid-3 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.columns-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.columns-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-57 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.bold-text-68 {
  color: #4d4d4d;
  font-weight: 400;
}

.bold-text-69 {
  color: #4d4d4d;
  font-weight: 400;
}

.bold-text-70 {
  font-weight: 400;
}

.bold-text-71 {
  color: #b50000;
  font-weight: 400;
}

.column-58 {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-5 {
  margin-right: 20px;
  margin-left: 20px;
  padding: 40px 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.instructions-section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.heading-9 {
  color: #b50000;
  text-align: center;
  margin-top: 0;
  font-weight: 400;
}

.bold-text-72 {
  color: #4d4d4d;
}

.text-block-116 {
  color: #262626;
  text-align: center;
  margin-top: 20px;
  font-family: Proximanova, sans-serif;
  font-size: 24px;
}

.heading-10 {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.container-9 {
  margin-top: 20px;
}

.tabs-5 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.column-59 {
  display: block;
  padding: 10px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-60 {
  display: block;
  padding: 10px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-11 {
  margin-bottom: 20px;
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.heading-12 {
  margin-bottom: 20px;
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.text-block-117 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .stop-image img {
    width: 30%;
  }
  .setupTitle {
    font-size: 2rem;
  }

  .instructions {
    font-size: 1.7rem !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .stop-image img {
    width: 20%;
  }

  .instructions {
    font-size: 1.9rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .stop-image img {
    width: 20%;
  }
}

@media screen and (min-width: 1025px) {
  .stop-image img {
    width: 20%;
  }
}

.heading-13 {
  color: #4d4d4d;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 400;
}

.container-7 {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.hero-wrapper-2 {
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  display: flex;
}

.text-block-118 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
}

.heading-14 {
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 10px;
  font-weight: 400;
}

.text-block-119 {
  margin-bottom: 10px;
  font-size: 14px;
}

.bold-text-73 {
  color: #b50000;
  font-weight: 400;
}

.image-17 {
  text-align: center;
  border-radius: 10px;
}

.div-block-6 {
  text-align: center;
}

.rich-text-block {
  text-align: left;
  font-size: 16px;
}

.rich-text-block-2 {
  text-align: left;
  font-size: 16px;
}

.heading-15 {
  text-align: left;
}

.heading-16 {
  text-align: left;
  padding: 20px;
}

.rich-text-block-3 {
  text-align: left;
  font-size: 16px;
}

.heading-17 {
  text-align: left;
  padding: 20px;
}

.body-5 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #262626;
}

.heading-18 {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.list-3 {
  margin-right: 20px;
  margin-left: 20px;
  padding-left: 0px;
  font-size: 16px;
}

.column-21 {
  text-align: center;
}

.setup-instructions-section {
  background-color: #ecedf1;
  border-radius: 10px;
  margin: 100px 30px;
  padding: 60px 30px;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.button-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
  font-size: 18px;
  text-align: center;
}

.button-5:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.button-5.w--current {
  height: 50px;
  border-radius: 20px;
}

.buton-div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  text-align: center;
}

.list-item-3 {
  margin-top: 10px;
}

.list-internet-activity {
  margin-top: 10px;
  color: #333;
  font-family: Proximanova, sans-serif;
  line-height: 20px;
}

.list-blocked {
  margin-top: 10px;
  color: #333;
  font-family: Proximanova, sans-serif;
  line-height: 20px;
}

.list-privacy-insights {
  margin-top: 10px;
  color: #333;
  font-family: Proximanova, sans-serif;
  line-height: 20px;
}

.link-22 {
  background-color: transparent;
}

/* .privacy-div-block {
  scroll-margin-top: 150px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 18px;
} */

.privacy-questions-div-block {
  scroll-margin-top: 150px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 18px;
}

.section-25 {
  margin: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
}

.div-block-7 {
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 0px;
  font-size: 18px;
}

.tips-div-block {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 18px;
}

.general-questions-div {
  scroll-margin-top: 150px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.technical-questions-div-block {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 18px;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-61 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-right: 2px none #000;
  background-color: transparent;
}

.div-block-12 {
  display: block;
  margin: 10px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-13 {
  display: block;
  max-height: none;
  max-width: none;
  margin: 10px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-14 {
  display: block;
  margin: 10px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-15 {
  display: block;
  margin: 10px;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-62 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  text-align: center;
}

.columns-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #000;
}

.div-block-16 {
  display: block;
  max-width: 400px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
}

.column-63 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: transparent;
}

.columns-32 {
  display: block;
  max-width: 100%;
  min-width: 50%;
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
}

.column-64 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 10px;
  background-color: transparent;
}

.column-65 {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 0px;
  background-color: transparent;
}

.column-66 {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.columns-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-19 {
  margin-top: 40px;
  color: #4d4d4d;
}

.text-block-120 {
  font-size: 18px;
  text-align: center;
}

.body-6 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
}

.section-26 {
  margin-right: 20px;
  margin-left: 20px;
}

.body-7 {
  background-color: #ecedf1;
}

.rich-text-block-4 {
  margin-right: 20px;
  margin-left: 20px;
  font-size: 16px;
}

.body-8 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #262626;
}

.section-4 {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin: 100px 30px;
  padding: 60px 30px;
  display: flex;
}

.text-block-43 {
  text-align: center;
  border: 1px #000;
  margin-top: 40px;
  padding-top: 0;
  font-size: 18px;
}

.bold-text-58 {
  font-family: Proximanova, sans-serif;
  color: #b50000;
  font-size: 24px;
  font-weight: 400;
}

.text-block-45 {
  margin-top: 40px;
  font-family: Proximanova, sans-serif;
  font-size: 16px;
  text-align: center;
}

.div-block-18 {
  display: block;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-44 {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.list-4 {
  display: inline-block;
  margin-top: 10px;
  padding-left: 0px;
  font-size: 16px;
  text-align: left;
}

.section-27 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.div-block-19 {
  border-radius: 50px;
  background-color: #fff;
  text-align: center;
}

.button-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  font-family: Proximanova, sans-serif;
  color: #b50000;
  font-size: 20px;
  font-weight: 400;
}

.button-6:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.heading-20,
.heading-21 {
  margin-top: 40px;
  margin-bottom: 0;
  font-weight: 400;
}

.heading-22 {
  color: #4d4d4d;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 400;
}

.text-block-121 {
  font-size: 16px;
}

.link-23 {
  color: #b50000;
}

.bold-text-124 {
  color: #b50000;
  font-weight: 400;
}

.div-block-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  font-size: 16px;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-122 {
  margin-right: 0px;
  border-radius: 0px;
  color: #b50000;
  font-size: 18px;
}

.text-block-123 {
  margin-right: 10px;
  border-radius: 0px;
  color: #b50000;
  font-size: 18px;
}

.text-block-124 {
  margin-right: 0px;
  padding: 0px;
  border-radius: 0px;
  color: #b50000;
  font-size: 18px;
}

.heading-23 {
  margin-top: 40px;
  font-weight: 400;
}

.value-prop-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  max-width: 90%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-18 {
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.image-19 {
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.image-20 {
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-125 {
  margin-top: 40px;
  font-size: 18px;
}

.list-5 {
  font-size: 16px;
  text-align: left;
}

.columns-35 {
  margin-top: 10px;
}

.heading-24 {
  font-weight: 400;
  text-align: center;
}

.heading-25 {
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: 400;
}

.columns-36 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-67 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-68 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.columns-37 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-69 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-70 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-23 {
  max-width: 100%;
  min-width: 100%;
}

.columns-38 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-71 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-72 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.body-9 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #262626;
  text-align: left;
}

.text-block-89 {
  padding-top: 20px;
  color: #b50000;
  font-size: 24px;
}

.privacy-insights-columns {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 0px;
  background-color: transparent;
  color: #4d4d4d;
}

.text-block-86 {
  margin-top: 10px;
  padding-top: 10px;
  font-size: 24px;
}

.bold-text-81 {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
}

.bold-text-81:hover {
  color: #00b0f0;
}

.image-21 {
  display: block;
  width: 75px;
  height: 75px;
  padding: 5px;
  border-style: none;
  border-width: 3px;
  border-color: #00b050;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-82 {
  border-bottom: 1px dotted #000;
  text-align: center;
}

.text-block-80 {
  display: block;
  padding-top: 20px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4d4d4d;
  font-size: 24px;
  text-align: center;
}

.privacy-detail-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
}

.bold-text-76 {
  border-bottom: 1px dotted #000;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
}

.bold-text-76:hover {
  color: #00b0f0;
}

.bold-text-78 {
  border-bottom: 1px dotted #000;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
}

.bold-text-78:hover {
  color: #00b0f0;
}

.bubble-chart-columns {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 0px;
  background-color: transparent;
}

.text-block-85 {
  margin-top: 0px;
  font-size: 24px;
}

.privacy-insights-section {
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 1px;
  background-color: transparent;
}

.column-74 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-83 {
  text-align: center;
}

.threat-meter-column {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.image-22 {
  width: 75px;
  height: 75px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-81 {
  padding-top: 20px;
  padding-bottom: 0px;
  color: #4d4d4d;
  font-size: 24px;
  text-align: center;
}

.proxima-nova-text-block {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 18px;
  text-align: left;
}

.text-block-84 {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
}

.bold-text-10 {
  color: #262626;
  font-size: 24px;
  font-weight: 400;
}

.image-23 {
  width: 75px;
  height: 75px;
  padding: 5px;
  border-style: none;
  border-width: 3px;
  border-color: #00b050;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.image-24 {
  width: 75px;
  height: 75px;
  padding: 5px;
  border-style: none;
  border-width: 3px;
  border-color: #00b050;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-126 {
  padding-top: 10px;
  text-align: center;
}

.heading-26 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
}

/* .showUpgradeText {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  display: flex;
  justify-content: center;
} */

/* .hrstyle {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(54, 49, 49, 0.527);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
} */

.upgrade-text {
  font-size: 1.6em;
  color: #b50000;
  font-weight: bold;
}

.action-buttons-section {
  margin-right: 40px;
  margin-bottom: 40px;
  margin-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
}

.columns-39 {
  margin: 0px;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-16 {
  margin-top: 10px;
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.column-17 {
  margin-bottom: 10px;
}

.field-label-2 {
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-weight: 400;
}

.heading-27 {
  margin-left: 0px;
  font-weight: 400;
}

.field-label {
  margin-top: 10px;
  font-family: Proximanova, sans-serif;
  color: #262626;
  font-weight: 400;
}

.column-15 {
  margin-bottom: 0px;
  border: 1px solid #000;
}

.field-label-4 {
  font-weight: 400;
}

.submit-button {
  width: 75px;
  height: 75px;
  border: 1px none #000;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  direction: ltr;
  color: #b50000;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  white-space: pre-wrap;
}

.submit-button:hover {
  border-style: none;
  box-shadow: 1px 1px 1px 2px #000;
}

.email-signup-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.grid-5 {
  background-color: #ecedf1;
  border-radius: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 40px;
  padding: 10px;
}

.email-signup-columns {
  margin-top: 20px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px none #000;
  background-color: #fff;
  white-space: break-spaces;
}

.form-block {
  font-family: Proximanova, sans-serif;
  text-align: center;
}

.threat-meter-section {
  position: relative;
  margin-right: 40px;
  margin-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.internet-activity-section {
  margin-right: 40px;
  margin-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blocked-domain-section {
  margin-right: 40px;
  margin-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.column-75 {
  /* position: relative; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-24 {
  display: block;
  padding: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.columns-40 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-76 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.column-77 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.text-block-127 {
  color: #262626;
  font-size: 12px;
  text-align: left;
}

.image-25 {
  width: 65px;
  height: 65px;
  max-width: none;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-78 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-128 {
  color: #262626;
  font-size: 12px;
  text-align: left;
}

.columns-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.div-block-25 {
  display: block;
  padding: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-79 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-26 {
  display: block;
  padding: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-80 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-129 {
  color: #262626;
  font-size: 12px;
  text-align: left;
}

.columns-42 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.text-block-130 {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
}

.column-81 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-82 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.column-83 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.column-84 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.div-block-27 {
  display: block;
  padding: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.columns-43 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-85 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.column-86 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-28 {
  display: block;
  padding: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-29 {
  display: block;
  padding: 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.image-26 {
  width: 75px;
  height: 75px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-87 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.column-88 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.column-89 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-90 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.columns-44 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.columns-45 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.activity-insights-row {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.column-92 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-block-133 {
  color: #4d4d4d;
}

.text-block-134 {
  font-size: 12px;
}

.text-block-135 {
  font-size: 12px;
}

.text-block-136 {
  font-size: 12px;
}

.image-27 {
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.image-28 {
  padding: 5px;
  border-radius: 10px;
  background-color: #eee;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.image-29 {
  padding: 5px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-30 {
  text-align: center;
}

.div-block-30-across {
  text-align: center;
  justify-content: space-evenly;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-pack: end; */
  -webkit-justify-content: space-evenly;
}

.column-94 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.text-block-137 {
  font-size: 24px;
}
.FetchingAndSpinner {
  display: flex;
  justify-content: center;
}
.tab-pane-tab-2,
.tab-pane-freeform-block {
  padding-top: 40px;
  padding-bottom: 40px;
}

.text-block-138 {
  font-size: 12px;
  justify-content: space-between;
}

/* .text-block-139 {
  font-size: 12px;
} */

.block-apps-button {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  background-color: #ecedf1;
  background-image: url("../images/Apps_black.png");
  background-position: 50% 50%;
  background-size: auto 70px;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.block-apps-button:hover:enabled {
  box-shadow: 1px 1px 1px 2px #262626;
}

.block-websites-button {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  background-color: #ecedf1;
  background-image: url("../images/block-websites_BK.png");
  background-position: 50% 50%;
  background-size: auto 50px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.block-websites-button:hover:enabled {
  box-shadow: 1px 1px 1px 2px #262626;
}

.body-10 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #262626;
}

.text-block-76 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: left;
}

.first-row-buttons {
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.blockAppsContent {
  width: 50%;
}

.classButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #b50000; */
}

.appsContainer {
  margin-top: 10px;
  border-radius: 5px;
  display: grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding-top: 10px;
}

.appDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  width: 25%;
}

.appName {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 560px) {
  .blockAppsContent {
    width: 90%;
  }
  .classButtons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .classButtons > button {
    width: 95%;
  }

  .appDetails {
    width: 50%;
  }
}

@media screen and (min-width: 561px) and (max-width: 768px) {
  .blockAppsContent {
    width: 480px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .blockAppsContent {
    width: 480px;
  }
}

.text-block-75 {
  color: #b50000;
}

.twitter-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/X-icon-black.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.twitter-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.instagram-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/circle_colored_gradient_instagram_media_icon.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.instagram-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.second-row-buttons {
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.tiktok-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/tik-tok_tiktok_icon.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.tiktok-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.linkedin-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/linkedin_social_icon.png");
  background-position: 50% 50%;
  background-size: 100px 100px;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.linkedin-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.yelp-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/yelp_icon.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.yelp-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.whatsapp-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/whatsapp_logo_icon.png");
  background-position: 50% 50%;
  background-size: 100px 100px;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.whatsapp-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.vudu-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/ps_vudu_logo.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.vudu-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.third-row-buttons {
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.youtube-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/youtube.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.youtube-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.twitch-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/twitch.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.twitch-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.roblox-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/roblox.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.roblox-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.fortnite-button {
  width: 75px;
  height: 75px;
  border: 2px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/fortnite.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.fortnite-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.heading-28 {
  font-family: Montserrat, sans-serif;
  /* color: #262626; */
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.app-buttons-section {
  background-color: rgba(0, 0, 0, 0);
  margin-top: 0;
  padding: 40px 30px;
}

.text-block-213 {
  margin-bottom: 20px;
  font-size: 16px;
}

.facebook-button {
  width: 75px;
  height: 75px;
  border: 1px none #000;
  border-radius: 50px;
  background-color: transparent;
  background-image: url("../images/circle_facebook_icon.png");
  background-position: 50% 50%;
  background-size: 100px 100px;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.facebook-button:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

/* .container {
  border-radius: 0px;
  background-color: transparent;
} */

.container-new {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  display: flex;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-30 {
  margin-top: 30px;
  font-family:
    Proxima nova alt,
    sans-serif;
  color: #262626;
  text-align: center;
}

.button-7 {
  width: auto;
  height: auto;
  color: #262626;
  text-align: center;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.button-7:hover {
  background-color: #fc9c02;
  box-shadow: 1px 1px 1px 2px #262626;
}

.div-block-31 {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: #fff;
  /* box-shadow: 3px 3px 3px 3px #cecbcb; */
  object-fit: fill;
}

.text-block-140 {
  margin-bottom: 10px;
  font-size: 16px;
}

.field-label-3 {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.section-28 {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.submit-button-3 {
  display: block;
  width: 75px;
  height: 75px;
  float: none;
  clear: none;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  direction: ltr;
  color: #262626;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  letter-spacing: normal;
  text-transform: capitalize;
  -webkit-column-count: auto;
  column-count: auto;
  white-space: normal;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  mix-blend-mode: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.submit-button-3:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.submit-button-2 {
  width: 75px;
  height: 75px;
  border: 1px none #000;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.submit-button-2:hover {
  box-shadow: 1px 1px 1px 2px #000;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
}

.form-block-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-block-2 {
  background-color: #fff;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.form-block-3 {
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-32 {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-33 {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.button-8 {
  width: 75px;
  height: 74px;
  margin-top: 40px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/icons_8.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  color: #262626;
  text-align: center;
}

.button-8:hover:enabled {
  box-shadow: 1px 1px 1px 2px #262626;
}

.text-block-141 {
  margin-top: 10px;
  color: #262626;
  font-size: 16px;
}

.button-9 {
  width: 75px;
  height: 75px;
  margin-top: 40px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/key.png");
  background-position: 50% 50%;
  background-size: 75% 75%;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  color: #262626;
}

.button-9:hover:enabled {
  box-shadow: 1px 1px 1px 2px #262626;
}

.text-block-142 {
  margin-top: 10px;
  font-size: 16px;
}

.div-block-34 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #4d4d4d;
}

.div-block-35 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #4d4d4d;
}

.columns-46 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.columns-47 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.text-block-143 {
  color: #262626;
  font-size: 12px;
  text-align: left;
}

.text-block-144 {
  margin-top: 0px;
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
}

.text-block-205 {
  font-size: 16px;
}

.column-95 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-96 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.columns-48 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.text-block-145 {
  color: #262626;
  font-size: 12px;
  text-align: left;
}

.column-97 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.text-block-146 {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
}

.column-98 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-36 {
  /* max-width: 75%; */
  /* min-width: 75%; */
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
  display: flex;
}

.tabs-3 {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px none #000;
  border-radius: 10px;
  background-color: #fff;
}

.tab-link-login {
  background-color: #fff;
}

.tab-link-login.w--current {
  min-width: 50%;
  border-radius: 10px;
  background-color: #b50000;
  text-align: center;
}

.text-block-147 {
  background-color: transparent;
}

.text-block-175 {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  font-size: 25px;
  font-weight: 400;
}

.text-block-176 {
  color: #4d4d4d;
  margin-bottom: 20px;
  padding-top: 0;
  font-family:
    Proxima nova,
    sans-serif;
  font-weight: 700;
}

.bold-text-96 {
  color: #b50000;
  font-weight: 400;
}

.checkbox-label-4 {
  color: #4d4d4d;
  text-align: left;
  margin-top: 0;
  font-size: 20px;
  font-weight: 400;
}

.tabs-menu-3 {
  display: block;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.tabs-content-3 {
  border-radius: 10px;
}

.tab-link-sign-up {
  min-width: 50%;
  border-radius: 10px;
  background-color: #fff;
}

.tab-link-sign-up:focus {
  background-color: transparent;
}

.tab-link-sign-up.w--current {
  background-color: #fff;
}

.tab-link-router-set-up {
  text-align: center;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-router-set-up.w--current {
  text-align: center;
  background-color: #fc9c02;
  border-radius: 10px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-device-by-device-set-up.w--current {
  background-color: #fc9c02;
}

.image-30 {
  margin: 20px;
}

.image-31 {
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  box-shadow: 1px 1px 1px 1px #cecbcb;
}

.tab-link-tab-3 {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  box-shadow:
    -3px 0 1px #fff,
    2px 2px 1px #000;
}

.tab-link-tab-3.w--current {
  background-color: #fc9c02;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.div-38 {
  max-width: none;
  mix-blend-mode: normal;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
  padding: 20px 10px;
  display: flex;
}

.text-block-148 {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.heading-29 {
  margin-right: 20px;
  margin-left: 20px;
  font-weight: 400;
}

.heading-30 {
  color: #262626;
  margin: 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 400;
}

.checkbox-label-2 {
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 400;
}

.checkbox-field {
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 0;
}

.submit-button-4 {
  width: 75px;
  height: 75px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 2px #cecbcb;
  color: #b50000;
  font-size: 18px;
  text-align: center;
}

.form-3 {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.text-block-212 {
  font-family: Montserrat, sans-serif;
}

.kavalan-button {
  width: auto;
  height: 50px;
  color: #4d4d4d;
  text-align: center;
  background-color: #fc9c02;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  box-shadow:
    2px 2px 1px #000,
    -2px -2px 1px #fff;
}

.grid-4 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.button-container {
  width: 30%;
  margin: 7px;
}

@media screen and (max-width: 724px) {
  .grid-4 {
    flex-direction: column;
  }
  .button-container {
    width: 95%;
    margin-top: 20px;
  }
}

.kavalan-button-new {
  /* width: -webkit-fill-available; */ /*doesn't work for Firefox*/
  width: 100%;
  height: 50px;
  color: #4d4d4d;
  text-align: center;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  box-shadow:
    2px 2px 1px #000,
    -2px -2px 1px #fff;
}

/* .kavalan-button-new:hover,.kavalan-button:hover {
  color: #262626;
  background-color: #fc9c02;
} */
.kavalan-button-new:hover:enabled {
  background-color: #fc9c02;
  box-shadow: 1px 1px 1px 2px #262626;
}

.faqs-button {
  height: 55px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.faqs-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
@media screen and (max-width: 568px) {
  .faqs-buttons-container {
    flex-direction: column;
  }
  .faqs-button {
    width: 100%;
  }
}

.faqs-title {
  margin-top: 40px;
}

.kavalan-button:active {
  color: #b50000;
  background-color: #fc9c02;
  box-shadow:
    1px 1px 3px #6f6e6e,
    -1px -1px 3px #000;
}

.section-29,
.section-30,
.section-31,
.div-block-77 {
  margin-top: 60px;
}

.form-block-4 {
  background-color: #ecedf1;
  border: 2px #000;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 20px;
  display: block;
}

.link-24 {
  margin-top: 20px;
  color: #262626;
}

.link-25 {
  margin-top: 10px;
  color: #262626;
}

.heading-31 {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 400;
}

.bold-text-82 {
  color: #b50000;
  font-weight: 400;
}

.checkbox-2 {
  background-color: #b50000;
}

.heading-32 {
  margin-top: 20px;
  font-weight: 400;
}

.radio-button-label-2 {
  font-size: 20px;
  font-weight: 400;
}

.radio-button-label-3 {
  font-weight: 400;
}

.radio-button-label-4 {
  font-weight: 400;
  text-align: left;
}

.radio-button-field-2 {
  margin-top: 10px;
  font-size: 20px;
  text-align: left;
}

.radio-button-field-3 {
  margin-top: 20px;
  font-size: 20px;
  text-align: left;
}

.div-block-74 {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  display: flex;
}

.submit-button-5 {
  width: 75px;
  height: 75px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 20px;
}

.form-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ecedf1;
}

.form-4 {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.button-10 {
  width: 75px;
  height: 75px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 2px #cecbcb;
  color: #b50000;
  font-size: 20px;
}

.button-10:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.button-10:active {
  box-shadow: none;
}

.checkbox-label-3 {
  font-size: 18px;
  font-weight: 400;
}

.checkbox-field-2 {
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 20px;
}

.checkbox-field-3 {
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  display: block;
}

.heading-75 {
  font-size: 20px;
  font-weight: 400;
}

.heading-67 {
  font-weight: 400;
}

.text-block-177 {
  font-size: 18px;
}

.text-block-178 {
  margin-top: 15px;
  font-size: 20px;
}

.bold-text-97 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-98 {
  color: #b50000;
  font-weight: 400;
}

.div-block-64 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.column-115 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.columns-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.text-block-179 {
  font-size: 12px;
  text-align: left;
}

.columns-49 {
  display: block;
  margin-top: 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  font-size: 14px;
}

.image-41 {
  width: 25%;
}

.image-32 {
  margin-top: 20px;
}

.image-33 {
  margin-top: 20px;
}

.heading-33 {
  margin-top: 60px;
  font-weight: 400;
}

.container-6 {
  width: 100%;
  max-width: 940px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 20px;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.hero-heading-right {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #e4ebf3;
  padding: 40px 30px;
  font-size: 20px;
  position: relative;
}

.button-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  direction: ltr;
  color: #b50000;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
}

.button-11:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.button-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 20px;
}

.heading-71 {
  color: #4d4d4d;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 400;
}

.button-12:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.div-block-38 {
  max-width: 50%;
  min-width: 50%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.button-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  color: #b50000;
  font-size: 20px;
}

.bold-text-83 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-84 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-85 {
  color: #b50000;
  font-weight: 400;
}

.button-26 {
  color: #262626;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-top: 0;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.button-26-new {
  color: #262626;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-top: 30px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.button-26:hover,
.button-26-new:hover {
  background-color: #fc9c02;
}

.div-block-78 {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.heading-85 {
  margin-top: 60px;
  margin-bottom: 40px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.tab-link-annual {
  text-transform: uppercase;
  background-color: #dadde0;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow:
    -1px -1px 1px #fff,
    4px 4px 9px -3px #6f6e6e;
}

.tab-link-annual.w--current {
  color: #262626;
  background-color: #fc9c02;
  border-radius: 10px;
  box-shadow:
    -1px 0 1px #fff,
    4px 4px 9px -3px #6f6e6e;
}

.tabs-4 {
  /* background-color: #ecedf1; */
  background-color: #fff;
  border-radius: 10px;
  flex: none;
  margin-top: 20px;
  padding: 40px 20px;
  display: block;
}

.link-27 {
  color: #b50000;
}

.w-tab-menu > button {
  margin-top: 5px;
}

.tabs-menu-4 {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  justify-content: center;
  margin-top: 0;
  display: flex;
}

.tabs-content-4 {
  border-radius: 10px;
  overflow: visible;
}

.tab-link-monthly {
  text-transform: uppercase;
  background-color: #dadde0;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow:
    -1px -1px 1px #fff,
    4px 4px 9px -3px #6f6e6e;
}

.tab-link-monthly.w--current {
  color: #262626;
  background-color: #fc9c02;
}

.text-block-195 {
  font-size: 90%;
}

.heading-34 {
  margin-top: 40px;
  color: #262626;
  font-weight: 400;
}

/* updated from 10 px to 20 px*/
.tab-pane-monthly {
  margin-top: 20px;
  margin-bottom: 20px;
}

.div-block-39 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 20px;
  padding: 20px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-40 {
  border-radius: 10px;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.list-6 {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 0px;
  font-size: 18px;
  text-align: left;
  list-style-type: square;
}

.bold-text-86 {
  color: #b50000;
  font-weight: 400;
}

.heading-35 {
  font-weight: 400;
}

.text-block-150 {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 40px;
  padding: 10px;
  font-size: 32px;
  font-style: normal;
}

/* .grid-4 {
  grid-column-gap: 82px;
  grid-row-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
} */

.text-block-151 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.heading-36 {
  color: #b50000;
  font-weight: 400;
}

.columns-50 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 20px;
  padding-top: 0;
  display: flex;
}

.button-14 {
  border-radius: 50px;
}

.kavalan-light-annual-button {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 75px;
  height: 75px;
  float: none;
  clear: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  mix-blend-mode: normal;
}

.tab-pane-previously-accessed-domain {
  padding-top: 40px;
  padding-bottom: 40px;
}

.kavalan-light-annual-button:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.tab-link-option-2 {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  box-shadow:
    -3px 0 1px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-option-2.w--current {
  background-color: #fc9c02;
  border-radius: 10px;
  box-shadow:
    -3px 0 1px #fff,
    2px 2px 1px #000;
}

.column-99 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: auto;
  align-items: center;
  padding: 20px 10px;
  display: flex;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.column-100 {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: auto;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.button-15 {
  width: 75px;
}

.kavalan-plan-annual-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
}

.kavalan-plan-annual-button:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.text-block-152 {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 40px;
  padding: 10px;
  font-size: 32px;
  font-style: normal;
}

.text-block-153 {
  border-radius: 10px;
  background-color: #fff;
}

.text-block-154 {
  margin: 0px;
  padding: 0px;
  color: #262626;
}

.bold-text-87 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-88 {
  color: #b50000;
  font-weight: 400;
}

.kavalan-light-monthly-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
}

.kavalan-light-monthly-button:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.tab-link-unblock-web-domains {
  background-color: #ecedf1;
  border-radius: 10px;
  box-shadow: 2px 2px 1px #000;
}

.tab-link-unblock-web-domains.w--current {
  background-color: #fc9c02;
  text-decoration: none;
  box-shadow: 2px 2px 1px #000;
}

.tabs-menu-6 {
  text-align: center;
}

.tab-link-tab-2 {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow:
    -3px 0 1px #fff,
    2px 2px 1px #000;
}

.tab-link-tab-2.w--current {
  background-color: #fc9c02;
}

.tab-link-tab-1 {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  box-shadow:
    2px 2px 1px rgba(0, 0, 0, 0.2),
    -3px 0 1px #fff;
}

.tab-link-tab-1.w--current {
  text-transform: uppercase;
  background-color: #fc9c02;
  border-radius: 10px;
  margin-right: 5px;
  font-size: 16px;
  box-shadow:
    1px 1px 1px #000,
    -3px 0 1px #fff;
}

.text-block-206 {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.kavalan-monthly-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
}

.kavalan-monthly-button:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.heading-37 {
  margin-top: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.bold-text-89 {
  color: #b50000;
  font-weight: 400;
}

.tab-link-block-web-domains {
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Lato, sans-serif;
  box-shadow: 2px 2px 1px #000;
}

.tab-link-block-web-domains.w--current {
  background-color: #fc9c02;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.block-unblock-tab-content {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.div-block-79 {
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px;
  display: flex;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-155 {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 40px;
  padding: 0;
  font-size: 32px;
  font-style: normal;
}

.heading-38 {
  color: #b50000;
  font-weight: 400;
}

.block-unblock-tab-menu {
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.div-block-88 {
  border-radius: 10px;
  padding: 30px 50px;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.columns-51 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  justify-content: center;
  display: flex;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.column-101,
.column-102 {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  display: flex;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.text-block-156 {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 40px;
  padding: 0;
  font-size: 32px;
  font-style: normal;
}

.column-103 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.text-block-209 {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.button-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
  text-align: center;
}

.button-16:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.free-trial-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #262626;
  text-align: center;
}

.div-block-41 {
  color: #262;
}

.heading-39 {
  padding: 20px;
  color: #4d4d4d;
  font-weight: 400;
}

.heading-40 {
  font-style: normal;
  font-weight: 400;
}

.tab-link-session-recording {
  text-align: center;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-session-recording.w--current {
  background-color: #fc9c02;
  border-radius: 10px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-keystroke-recording {
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-keystroke-recording.w--current {
  background-color: #fc9c02;
}

.tab-link-social-media {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Proximanova, sans-serif;
  font-weight: 600;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.tab-link-social-media.w--current {
  text-transform: uppercase;
  background-color: #fc9c02;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.tab-link-social-media.w--current:hover {
  background-color: #fc9c02;
}

.tab-link-streaming-apps {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Proximanova, sans-serif;
  font-weight: 600;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.tab-link-streaming-apps:hover,
.tab-link-streaming-apps.w--current {
  background-color: #fc9c02;
}

.tab-link-gaming {
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  font-family: Proximanova, sans-serif;
  font-weight: 600;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.tab-link-gaming:hover {
  background-color: #fc9c02;
}

.tab-link-gaming.w--current {
  background-color: #fc9c02;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.column-104 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.column-105 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.bold-text-90 {
  color: #b50000;
  font-weight: 400;
}

.column-106 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-107 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 18px;
  text-align: center;
}

.button-17:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.tab-link-free-trial {
  background-color: #dadde0;
  border-radius: 10px;
  margin-right: 5px;
  box-shadow:
    -1px -1px 1px #fff,
    4px 4px 9px -3px #6f6e6e;
}

.tab-link-free-trial.w--current {
  color: #262626;
  background-color: #fc9c02;
  border-radius: 10px;
  margin-right: 5px;
}

.bold-text-91 {
  color: #262626;
  font-weight: 400;
}

.list-7 {
  padding-left: 20px;
  font-size: 16px;
}

.heading-41 {
  font-weight: 400;
}

.heading-42 {
  font-weight: 400;
}

.technical-questions-div {
  scroll-margin-top: 150px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.div-block-42 {
  display: inline-block;
}

.tab-link-unencrypted-sites {
  background-color: #ecedf1;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-unencrypted-sites.w--current {
  background-color: #fc9c02;
}

.text-block-157 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.heading-43 {
  margin-top: 20px;
  font-weight: 400;
}

.bold-text-92 {
  color: #b50000;
  font-family: Montserrat, sans-serif;
  font-size: 44px;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .selfServiceTitle {
    font-size: 2rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .selfServiceTitle {
    font-size: 2rem;
  }
}

.body-11 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  font-weight: 400;
}

.div-block-43 {
  margin: 60px 20px;
}

.container-2 {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
}

.heading-44 {
  margin-top: 0;
  font-family: Proximanova, sans-serif;
  font-weight: 400;
}

.div-block-44 {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-45 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  text-align: center;
}

.heading-45,
.heading-46,
.heading-47 {
  color: #4d4d4d;
  font-weight: 400;
}

.button-18 {
  width: auto;
  height: auto;
  color: #262626;
  text-transform: uppercase;
  background-color: #fc9c02;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.button-18:hover {
  color: #b50000;
  background-color: #dadde0;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.columns-57 {
  justify-content: space-between;
  align-self: stretch;
  margin-top: 60px;
  display: flex;
}

.columns-52 {
  display: inline-block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.column-108 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.column-109 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  text-align: center;
}

.bold-text-93,
.bold-text-94,
.bold-text-95 {
  color: #262626;
  font-weight: 400;
}

.div-block-46 {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 20px;
  display: flex;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.heading-48 {
  font-weight: 400;
}

.columns-53 {
  color: #262626;
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-items: stretch;
  margin-top: 40px;
  display: flex;
}

.column-110,
.column-111 {
  justify-content: center;
  align-self: auto;
  display: flex;
}

.column-112 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* .text-block-164 {
  font-size: 12px;
  text-align: center;
} */

.text-block-165 {
  padding-top: 20px;
  color: #4d4d4d;
  font-size: 24px;
  text-align: center;
  margin-right: 50px;
}

.text-block-180 {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
}

.column-113 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.container-3 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.kavalan-light-upgrade-section {
  display: block;
  margin-right: 40px;
  margin-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.heading-49 {
  margin-bottom: 0px;
  font-weight: 400;
  text-align: left;
}

.text-block-158 {
  color: #262626;
  text-align: left;
}

.div-block-47 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.text-block-159 {
  color: #262626;
}

.heading-50 {
  font-weight: 400;
}

.heading-51 {
  font-weight: 400;
}

.heading-54 {
  margin-top: 15px;
  margin-bottom: 0px;
  font-weight: 400;
  margin-left: 10px;
}

.alerts-and-notifications-container {
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.popup-text-block-160 {
  margin-top: 5px;
  margin-bottom: 40px;
  font-size: 18px;
  text-align: center;
}

.bold-text-96 {
  font-family: Montserrat, sans-serif;
  color: #b50000;
  font-weight: 700;
}

.popup-text-block-161 {
  margin-bottom: 40px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  text-align: center;
  text-transform: none;
  -webkit-column-count: auto;
  column-count: auto;
  white-space: normal;
}

.popup-text-block-162 {
  margin-bottom: 40px;
  font-size: 16px;
  text-align: center;
}

.button-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/kavalan-helmet_color.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
  text-align: center;
}

.button-19:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.tooltip-box {
  position: absolute;
  display: inline-block;
}

.tooltip-box .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  min-width: 300px;

  position: absolute;
  z-index: 1;
}

.tooltip-box:hover .tooltiptext {
  visibility: visible;
  background-color: #e9e9e9;
  color: #4d4d4d;
  border: 1px solid #ccc;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  font-size: 12px;
  line-height: normal;
}

@media only screen and (max-width: 600px) {
  .tooltip-box {
    display: block;
    text-align: center;
  }

  .tooltiptext {
    position: static;
    margin-top: 10px;
  }
}

.div-block-48 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-49 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.adult-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ecedf1;
  background-image: url("../images/Block_icon_Black.png");
  background-position: 0px 0px;
  background-size: contain;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
  font-size: 30px;
}

.adult-button:hover:enabled {
  box-shadow: 1px 1px 1px 2px #262626;
}

.adult-button:active:enabled {
  background-color: #b50000;
}

.column-116 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup-text-block-163 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
}

.insight-popup {
  position: absolute;
  background-color: #e9e9e9;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 150%;
  line-height: normal;
}

.text-block-160 {
  margin-bottom: 20px;
  text-align: center;
}

.columns-55 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
}

.column-112 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-113 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column-114 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.image-36 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.text-field {
  width: 50%;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-domain-button {
  color: #262626;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 2px 2px 1px #000;
}

.block-domain-button:hover {
  background-color: #fc9c02;
}

.unblock-domain-button {
  color: #262626;
  text-align: center;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-top: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow:
    -3px 0 1px #fff,
    2px 2px 1px #000;
}

.unblock-domain-button:hover {
  background-color: #fc9c02;
}

.disabledbutton {
  color: gray;
}

.disabledbutton:hover {
  background-color: #ecedf1;
}

.text-block-210 {
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.image-37 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.text-field-2 {
  width: 50%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.body-12 {
  background-color: #ecedf1;
  font-family: Proximanova, sans-serif;
  color: #262626;
}

.tabs-content-7 {
  background-color: #ecedf1;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.tab-pane-session-recording {
  background-color: #ecedf1;
  border-radius: 0;
  margin-top: 0;
}

.text-block-215 {
  max-height: none;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: scroll;
}

.text-block-216 {
  color: #858181;
  margin-top: 100px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 14px;
  font-family: Proximanova, sans-serif;
}

.tabs-6 {
  max-width: 100%;
}

.tabs-7 {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 3px 3px 4px 3px #cecbcb;
}

.tabs-menu-9 {
  justify-content: center;
  display: flex;
}

.container-4 {
  color: #262626;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px 20px;
  display: flex;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.heading-52-0 {
  margin-top: 0px;
  font-family: Montserrat, sans-serif;
  color: #4d4d4d;
  font-weight: 400;
}

.heading-53 {
  margin-top: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.personal-info-div {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.text-block-211 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}

.heading-54 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.behavioral-div {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-220 {
  font-size: 16px;
}

.div-block-51 {
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  overflow: visible;
}

.pi-button-style {
  margin-right: 20px;
  border-radius: 10px;
  background-color: #fc9c02;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
}

.pi-button-style:active {
  background-color: #b50000;
}

.div-block-52 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-field-3 {
  border-radius: 10px;
}

.form-5 {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.slider {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: block;
  overflow: visible;
}

.pi-slider-header {
  font-family: Proximanova, sans-serif;
  font-weight: 400;
  text-align: center;
}

.heading-55 {
  font-family: Proximanova, sans-serif;
  color: #4d4d4d;
  font-weight: 400;
  text-align: center;
}

.heading-56,
.heading-57,
.heading-58 {
  color: #262626;
  text-align: center;
  font-weight: 400;
}

.slide-nav {
  overflow: visible;
  border-radius: 10px;
  background-color: #4d4d4d;
  color: #fff;
  text-align: center;
}

.icon-2 {
  border-radius: 10px;
  background-color: transparent;
  color: #b50000;
}

.icon-3 {
  border-radius: 10px;
  background-color: transparent;
  color: #b50000;
}

.slide {
  overflow: scroll;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 10px;
}

.slide-2 {
  overflow: scroll;
  border-radius: 10px;
}

.icon-4 {
  border-radius: 10px;
  background-color: transparent;
  color: #b50000;
}

.icon-5 {
  border-radius: 10px;
  background-color: transparent;
  color: #b50000;
}

.heading-59 {
  font-weight: 400;
  text-align: center;
}

.heading-60 {
  font-weight: 400;
  text-align: center;
}

.heading-61 {
  font-weight: 400;
  text-align: center;
}

.heading-62 {
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.heading-63 {
  color: #262626;
  font-weight: 400;
  text-align: center;
}

.slider-2 {
  margin-top: 20px;
  border-radius: 0px;
  background-color: #fff;
}

.right-arrow {
  border-radius: 10px;
}

.left-arrow {
  border-radius: 10px;
}

.slide-nav-2 {
  border-radius: 10px;
  background-color: #4d4d4d;
}

.right-arrow-2 {
  border-radius: 10px;
}

.left-arrow-2 {
  border-radius: 10px;
}

.heading-83 {
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.heading-84 {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.pi-app-name-button-style {
  margin-right: 10px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
  text-align: center;
}

.unbreached-text-block {
  width: 150px;
  height: 30px;
  text-align: center;
  /* background-color: #fff; */
  background-color: #ecedf1;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
  /* box-shadow: 3px 3px 3px 3px #cecbcb; */
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.unbreached-text-block:hover {
  background-color: #fc9c02;
}

.loading-message {
  margin-top: 10px;
}

.div-block-53 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  overflow: auto;
}

.heading-64 {
  font-weight: 400;
  text-align: center;
}

.div-block-54 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.text-block-162 {
  margin-bottom: 10px;
  text-align: center;
}

.text-block-163 {
  margin-bottom: 20px;
  text-align: center;
}

.text-block-207 {
  border-radius: 10px;
  font-size: 16px;
}

.tabs-content-6 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.div-block-55 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 20px;
}

.text-block-164 {
  margin-bottom: 20px;
  text-align: center;
}

.div-block-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 20px;
}

.div-block-57 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow: scroll;
}

.div-block-58 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.slide-3 {
  overflow: scroll;
}

.slide-4 {
  overflow: scroll;
}

.slide-5 {
  overflow: scroll;
}

.slide-6 {
  overflow: scroll;
}

.slide-7 {
  overflow: scroll;
}

.slide-8 {
  overflow: scroll;
}

.slide-9 {
  overflow: scroll;
}

.mask {
  border-radius: 10px;
}

.breached-app-text-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  text-align: center;
  cursor: auto;
}

.text-block-166 {
  margin-top: 0px;
}

.div-block-59 {
  padding: 20px;
}

.modal {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999999;
  display: none;
  overflow: visible;
  width: 100%;
  height: 50%;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.pop-up {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  max-width: 30%;
  min-height: 75px;
  min-width: auto;
  margin: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-167 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.text-block-168 {
  margin: 20px;
}

.text-block-169 {
  height: auto;
  margin-top: 10px;
  text-align: center;
}

.div-block-60 {
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 40px;
  display: flex;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.text-block-218 {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.heading-65 {
  margin-bottom: 0px;
  font-size: 2px;
  font-weight: 400;
}

.heading-66 {
  margin-top: 40px;
  margin-bottom: 0px;
  font-weight: 400;
}

.text-block-170 {
  font-size: 24px;
}

.text-block-171 {
  font-size: 24px;
}

.text-block-172 {
  margin-top: 40px;
  font-size: 24px;
}

.text-block-173 {
  margin-top: 40px;
  font-size: 24px;
}

.text-block-174 {
  display: none;
}

.div-block-61 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.div-ani {
  width: 800px;
  position: relative;
  align-items: center;
  justify-content: center;
  left: 10px;
  -webkit-animation: slideIn 1s forwards;
  -moz-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

.image-38 {
  max-height: 75px;
  max-width: 75px;
  padding: 0px;
  border-radius: 10px;
  background-color: #ecedf1;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  margin-left: 0px;
  margin-top: 20px;
}

.text-block-175 {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
}

.bold-text-96 {
  color: #b50000;
  font-weight: 400;
}

.image-39 {
  max-width: 19%;
  margin-top: 40px;
}

.heading-67 {
  font-weight: 400;
}

.text-block-177 {
  font-size: 18px;
}

.bold-text-97 {
  color: #b50000;
  font-weight: 400;
}

.text-block-178 {
  font-size: 20px;
}

.bold-text-98 {
  color: #b50000;
  font-weight: 400;
}

.block-apps-button-2 {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/custom-apps-blocked_BK.png");
  background-position: 50% 50%;
  background-size: auto 50px;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.block-apps-button-2:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.block-websites-button-2 {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/block-websites_BK.png");
  background-position: 50% 50%;
  background-size: auto 50px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.div-block-62 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
}

.div-block-63 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.button-22 {
  display: inline-block;
  width: 75px;
  height: 75px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/Access-Atempts-Blocked_BK.png");
  background-position: 50% 50%;
  background-size: auto 50px;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  text-align: center;
}

.button-22:hover:enabled {
  box-shadow: 1px 1px 1px 2px #262626;
}

.button-23 {
  display: inline-block;
  width: 75px;
  height: 75px;
  margin-top: 40px;
  margin-bottom: 10px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("../images/Dashboard-Tiles-BW.png");
  background-position: 50% 50%;
  background-size: auto 50px;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  text-align: center;
}

.button-23:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.column-115 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.columns-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.text-block-179 {
  font-size: 12px;
}

.text-block-180 {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
}

.column-116 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-181 {
  margin-right: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
  padding-right: 20px;
  padding-left: 20px;
  color: #858181;
}

.text-block-182 {
  margin-top: 20px;
  color: #262626;
  text-align: center;
}

.text-block-183 {
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  font-size: 16px;
  text-align: center;
}

.image-40 {
  max-height: 75px;
  max-width: 75px;
  text-align: left;
}

.div-block-65,
.div-block-66,
.div-block-67,
.div-block-68,
.div-block-69,
.div-block-70,
.div-block-71,
.div-block-72 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.button-20 {
  min-height: auto;
  margin-top: 20px;
  min-width: 50px;
  border-radius: 10px;
  background-color: #fc9c02;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.button-21 {
  min-height: auto;
  margin-top: 20px;
  min-width: 50px;
  border-radius: 10px;
  background-color: #dddddd;
  box-shadow: 3px 3px 3px 3px #cecbcb;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.heading-52 {
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
}

.list-item-8 {
  margin-top: 10px;
}

.list-item-9 {
  margin-top: 10px;
}

.bold-text-99 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-100 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-101 {
  color: #b50000;
}

.bold-text-102 {
  color: #b50000;
}

.bold-text-103 {
  color: #b50000;
  font-weight: 400;
}

.list-item-10 {
  margin-top: 10px;
}

.bold-text-104 {
  color: #b50000;
}

.list-item-11 {
  margin-top: 10px;
}

.bold-text-105 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-106 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-107 {
  color: #b50000;
  font-weight: 400;
}

.list-item-12 {
  margin-top: 10px;
}

.list-item-13 {
  margin-top: 10px;
}

.bold-text-108 {
  color: #b50000;
  font-weight: 400;
}

.list-item-14 {
  margin-top: 10px;
}

.bold-text-109 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-110 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-111 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-112 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-113 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-114 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-115 {
  color: #b50000;
  font-weight: 400;
}

.text-block-184 {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ecedf1;
  text-align: center;
}

.text-block-185 {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.text-block-196 {
  color: #4d4d4d;
  text-align: center;
  margin-top: 40px;
}

.bold-text-123 {
  color: #b50000;
  font-weight: 400;
}

.bold-text-121 {
  color: #b50000;
  font-weight: 400;
}

.tab-pane-option-2 {
  padding-top: 0;
  padding-bottom: 0;
}

.heading-68 {
  color: #4d4d4d;
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 400;
}

.text-block-186 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #262626;
  font-size: 18px;
}

.tabs-menu-7 {
  text-align: center;
}

.unblock-options-tab {
  border: 1px #4d4d4d;
  border-radius: 0;
  padding: 10px;
}

.tab-link-option-1 {
  text-transform: uppercase;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow:
    -3px 0 1px #fff,
    2px 2px 1px #000;
}

.tab-link-option-1.w--current {
  text-transform: uppercase;
  background-color: #fc9c02;
  border-radius: 10px;
  margin-right: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.heading-69 {
  margin-bottom: 40px;
  color: #4d4d4d;
  font-weight: 400;
  text-align: center;
}

.text-block-187 {
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

.text-block-203 {
  font-size: 16px;
}

.text-block-188 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #262626;
  font-size: 18px;
}

.bold-text-116 {
  font-family: "Proxima nova", sans-serif;
  color: #b50000;
  font-weight: 700;
  margin-top: 10px;
}

.unblock-div-block {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 3px 3px #cecbcb;
}

.text-block-189 {
  text-align: center;
  margin-top: 40px;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-28,
.link-29 {
  color: #262626;
  text-align: center;
}

.bold-text-117 {
  font-family: "Proxima nova", sans-serif;
  color: #b50000;
  font-weight: 700;
}

.text-block-190 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.link-26 {
  color: #b50000;
  font-weight: 400;
}

.select-field {
  text-align: center;
  background-color: #ecedf1;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.select-field-1 {
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
}

.select-field-2 {
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
}

.select-field-3 {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
}

.shadow-two {
  box-shadow: 0 4px 24px rgba(150, 163, 181, 0.08);
}

.mandu-domain-block-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.div-block-80 {
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px;
  display: flex;
  box-shadow: 3px 3px 3px 3px #cecbcb;
}

.heading-76 {
  margin-top: 0;
}

.text-block-191 {
  margin-top: 15px;
  color: #b50000;
  font-weight: 400;
}

.text-block-192 {
  margin-top: 15px;
  color: #b50000;
  text-align: center;
}

.text-block-193 {
  color: #b50000;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.text-block-198,
.text-block-199,
.text-block-200 {
  margin-top: 40px;
  font-size: 18px;
}

.div-block-73 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.heading-70 {
  font-weight: 400;
}

.hero-wrapper {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

.shadow-two-2 {
  box-shadow: 0 4px 24px rgba(150, 163, 181, 0.08);
}

.hero-split {
  max-width: 46%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero-split-2 {
  max-width: 46%;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  display: flex;
}

.div-block-81,
.div-block-82,
.div-block-83,
.div-block-84,
.div-block-85,
.div-block-86,
.div-block-87 {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
}

.heading-80,
.heading-81,
.heading-82 {
  color: #b50000;
  text-align: center;
  font-weight: 400;
}

.text-block-208 {
  text-align: center;
  font-size: 18px;
}

.heading-78 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.video-3 {
  width: 400px;
  border: 1px solid #000;
  flex: none;
}

.container-10 {
  background-color: #fff;
  border-radius: 10px;
  flex: 0 auto;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.container-8 {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.div-block-75 {
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.heading-72 {
  color: #262626;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.tab-link-behavioral-information {
  background-color: #ecedf1;
  border-radius: 10px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-behavioral-information.w--current {
  background-color: #fc9c02;
}

.text-block-194 {
  text-align: center;
  margin-top: 20px;
  font-family: Proximanova, sans-serif;
  font-size: 18px;
}

.bold-text-120 {
  font-family:
    Proxima nova,
    sans-serif;
  font-weight: 700;
}

.heading-74 {
  color: #b50000;
  text-align: center;
  padding: 10px;
  font-family: Montserrat, sans-serif;
}

.div-block-76 {
  border-radius: 10px;
  margin-top: 40px;
  padding: 10px;
  box-shadow: 2px 2px 3px 3px #cecbcb;
}

.heading-73 {
  color: #262626;
  text-align: center;
  margin-top: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.tab-link-device-by-device-set-up {
  text-align: center;
  text-transform: uppercase;
  background-color: #ddd;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-personal-information {
  background-color: #ecedf1;
  border-radius: 10px;
  margin-right: 5px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tab-link-personal-information.w--current {
  background-color: #fc9c02;
  border-radius: 10px;
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
}

.tabs-content-5 {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
}

.text-block-201 {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.column-117 {
  flex: 1;
}

.heading-77 {
  color: #262626;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.text-block-217 {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

@media screen and (max-width: 991px) {
  .column-4 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .hero-wrapper-2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: -40px;
  }

  .navbar {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .video-2 {
    max-height: 100%;
    max-width: 100%;
    min-height: 0;
    justify-content: center;
    align-items: stretch;
    margin-top: 0;
    display: block;
  }

  .no-risk-trial-row-1-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-block-105 {
    padding-bottom: 0px;
    text-align: center;
  }

  .credit-card-columns {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .text-block-106 {
    padding-bottom: 0px;
    text-align: center;
  }

  .column-41 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-43 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block-195,
  .text-block-202 {
    text-align: center;
  }

  .image-12 {
    display: block;
  }

  .text-block-107 {
    padding-bottom: 0px;
    text-align: center;
  }

  .text-block-108 {
    margin-right: 0px;
    margin-left: 0px;
    padding-bottom: 0px;
    text-align: center;
  }

  .container-7 {
    max-width: 728px;
  }

  .button {
    margin-top: 20px;
  }

  .tabs-menu-2 {
    justify-content: space-between;
    align-items: center;
    display: block;
  }

  .button-4 {
    font-size: 16px;
  }

  .rich-text-block-3 {
    text-align: left;
    font-size: 16px;
  }

  .heading-17 {
    text-align: left;
  }

  .button-5.w--current {
    height: 100px;
    border-radius: 50px;
  }

  .div-block-8 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-9 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-10 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-62 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .hero-split {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .tabs-4 {
    /* background-color: #ecedf1; */
    background-color: #fff;
  }

  .columns-32 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 40px;
    margin-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .column-64 {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
  }

  .column-65 {
    display: block;
    border-radius: 0px;
    background-color: transparent;
  }

  .column-66 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 0px;
    background-color: transparent;
    text-align: center;
  }

  .value-prop-columns {
    width: 90%;
    max-width: 90%;
  }

  .text-block-80 {
    text-align: left;
  }

  .text-block-85 {
    margin-top: 0px;
  }

  .text-block-81 {
    text-align: center;
  }

  .column-76 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .column-78 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .text-block-128 {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-26 {
    width: auto;
    height: auto;
    min-height: 0px;
    min-width: 0px;
  }

  .column-80 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .text-block-129 {
    color: #262626;
  }

  .container-6 {
    max-width: 728px;
  }

  .hero-split-2 {
    max-width: 100%;
    align-self: center;
    margin-bottom: 40px;
  }

  .column-84 {
    display: block;
  }

  .columns-43 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-85 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-86 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-87 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-88 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .activity-insights-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .column-91 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .column-92 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .hero-heading-right {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    margin: 40px 20px;
  }

  .div-38 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .list-6 {
    margin-top: 10px;
  }

  .text-block-149 {
    font-size: 18px;
  }

  .bold-text-86 {
    color: #b50000;
    font-weight: 400;
  }

  .heading-35 {
    font-weight: 400;
  }

  .text-block-150 {
    font-size: 18px;
  }

  .column-106 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .icon {
    position: static;
    float: right;
  }

  .hero-wrapper {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -40px;
  }

  .container-10 {
    background-color: #fff;
    border-radius: 10px;
  }
}

@media screen and (max-width: 767px) {
  .sticky-nav {
    position: relative;
  }

  .nav-grid {
    text-align: center;
  }

  .video-columns {
    display: block;
    text-align: center;
  }

  .column-37 {
    display: inline-block;
  }

  .column-117 {
    flex: 0 auto;
  }

  .video-2 {
    align-items: stretch;
  }

  .no-risk-trial-row-1-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-12 {
    min-height: 75px;
    min-width: 75px;
    margin-right: 0px;
  }

  .image-13 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .image-16 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .video-3 {
    align-self: auto;
  }

  .kavalan-button {
    height: auto;
    min-height: 50px;
  }

  .column-59 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-12 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .hero-wrapper {
    justify-content: space-between;
    align-items: center;
  }

  .div-block-13 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .columns-57 {
    align-self: stretch;
  }

  .div-block-14 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .block-unblock-tab-menu {
    text-align: center;
  }

  .div-block-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .tab-link-annual,
  .tab-link-monthly {
    align-self: stretch;
  }

  .columns-31 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-63 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-64 {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    text-align: center;
  }

  .columns-33 {
    display: block;
  }

  .div-block-20 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-21 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .div-block-22 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .value-prop-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .columns-37 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .columns-38 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .grid-5 {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .privacy-insights-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-block-80 {
    text-align: center;
  }

  .tab-link-session-recording,
  .tab-link-keystroke-recording {
    margin-bottom: 5px;
  }

  .column-77 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-79 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-80 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .column-81 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-27 {
    display: block;
  }

  .columns-43 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-85 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .column-86 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-28 {
    display: block;
  }

  .hero-heading-right {
    padding: 60px 15px;
  }

  .column-87 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .hero-split-2 {
    align-self: center;
    align-items: center;
  }

  .column-88 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-89 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-90 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .columns-44 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .columns-45 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .activity-insights-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-91 {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .column-92 {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .column-93 {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .div-block-31 {
    flex-direction: column;
  }

  .columns-47 {
    margin-bottom: 10px;
  }

  .columns-48 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .div-block-36 {
    max-width: none;
  }

  .tab-link-tab-3 {
    margin-top: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  }

  .text-block-150 {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    font-size: 32px;
  }

  .text-block-152 {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    padding: 0;
  }

  .column-103 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .free-trial-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-104 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-105 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-106 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-107 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .columns-54 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .text-block-169 {
    margin-top: 20px;
  }

  .columns-56 {
    margin-top: 10px;
  }

  .hero-heading-center {
    padding: 60px 15px;
  }

  .heading-68 {
    margin-bottom: 20px;
  }

  .tabs-menu-8 {
    flex-direction: column;
    display: flex;
  }
}

@media screen and (max-width: 479px) {
  .tab-link-social-media,
  .tab-link-social-media.w--current {
    text-align: center;
    margin-right: 0;
  }

  .tab-link-streaming-apps {
    text-align: center;
    margin-top: 5px;
    margin-right: 0;
  }

  .tab-link-gaming {
    text-align: center;
    margin-top: 5px;
  }

  .tabs-7 {
    object-fit: fill;
    display: block;
  }

  .container-7 {
    max-width: none;
  }

  .image-17 {
    width: 99.9998%;
  }

  .tabs-menu-5 {
    flex-direction: column;
    flex: 0 auto;
    justify-content: space-between;
    align-self: stretch;
    display: flex;
  }

  .columns {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .column-2 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-3 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .column-4 {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .sticky-nav {
    padding: 0px;
  }

  .nav-grid {
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }

  .nav-logo-link {
    border-top: 1px solid #c4c4c4;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }

  .video-3 {
    width: 90%;
    min-width: 0;
  }

  .video-columns {
    display: block;
    margin-right: 20px;
    margin-left: 20px;
  }

  .column-37 {
    display: block;
  }

  .video-2 {
    width: 90%;
    height: auto;
    max-height: none;
    max-width: none;
    display: block;
  }

  .tab-link-block-web-domains {
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0;
    font-family: Montserrat, sans-serif;
  }

  .tab-link-block-web-domains.w--current {
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .heading-3 {
    color: #b50000;
  }

  .text-block-11 {
    text-align: center;
  }

  .text-block-102 {
    text-align: center;
  }

  .kavalan-button {
    height: auto;
    min-height: 50px;
  }

  /* .grid-4 {
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
  } */

  .setup-instructions-section {
    padding-left: 10px;
    padding-right: 10px;
  }

  .instructions-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .email-sign-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tabs-menu-9 {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .text-block-157 {
    text-align: center;
    display: block;
  }

  .tab-pane-tab-2 {
    text-align: center;
  }

  .app-buttons-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .no-risk-trial-row-1-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .image-11 {
    margin-right: 0px;
  }

  .text-block-176 {
    text-align: center;
  }

  .text-block-105 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .credit-card-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tab-link-router-set-up {
    margin-right: 0;
  }

  .tab-link-router-set-up.w--current {
    flex-direction: column;
    justify-content: space-between;
    align-self: auto;
    align-items: stretch;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
  }

  .tab-link-option-1 {
    text-align: center;
    background-color: #ecedf1;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tab-link-option-1.w--current {
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tab-link-personal-information {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tab-link-personal-information.w--current {
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tabs-content-8 {
    margin-top: 10px;
  }

  .tab-link-option-2 {
    text-align: center;
  }

  .text-block-210,
  .text-block-211 {
    text-align: center;
  }

  .text-block-219 {
    text-align: center;
    font-size: 16px;
  }

  .personal-info-div {
    background-color: #fff;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 40px 10px;
  }

  .grid-5 {
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
  }

  .text-block-217 {
    text-align: center;
  }

  .text-block-106 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .block-domain-button {
    text-align: center;
  }

  .image-12 {
    max-height: none;
    min-height: 75px;
    min-width: 75px;
    margin-right: 0px;
  }

  .text-block-107 {
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }

  .tabs-4 {
    align-self: stretch;
    margin-top: 40px;
  }
  .productContent {
    margin-top: 0;
  }

  .image-13 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .columns-50 {
    flex-direction: column;
    display: flex;
  }

  .image-16 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .block-unblock-tab {
    width: 90%;
    max-width: none;
    min-width: auto;
    flex: 0 auto;
  }

  .tab-link-linksys,
  .tab-link-netgear,
  .tab-link-comcast-xfinity {
    text-align: center;
  }

  .tab-link-verizon {
    text-align: center;
    margin-left: 0;
  }

  .tab-link-tplink {
    text-align: center;
  }

  .tab-link-asus {
    text-align: center;
  }

  .tab-link-other {
    text-align: center;
    border-style: none;
    margin-right: 10px;
  }

  .tab-link-device-by-device-set-up {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .tab-link-keystroke-recording {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tab-link-behavioral-information {
    text-align: center;
  }

  .text-block-218 {
    font-size: 16px;
  }

  .container-6 {
    max-width: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    display: block;
  }

  .container {
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }

  .tab-link-session-recording {
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .columns-29 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .div-block-74 {
    justify-content: space-between;
  }

  .column-56 {
    margin-bottom: 10px;
  }

  .heading-71 {
    text-align: center;
  }

  .shadow-two {
    width: 100px;
    height: 100px;
  }

  .bold-text-69 {
    font-weight: 400;
  }

  .hero-wrapper {
    flex-direction: column;
  }

  .tab-link-monthly {
    text-align: center;
    margin: 5px 0;
    display: block;
  }

  .column-59 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
  }

  .tab-link-tab-1 {
    text-align: center;
    margin-bottom: 5px;
  }

  .tab-link-tab-1.w--current {
    margin-right: 0;
  }

  .tab-link-tab-2 {
    text-align: center;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .tab-link-tab-2.w--current {
    text-align: center;
    margin-bottom: 5px;
  }

  .column-60 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .tab-link-unblock-web-domains {
    text-align: center;
  }

  .buton-div-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .div-block-8 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero-split {
    flex: none;
  }

  .div-block-9 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-11 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-12 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .div-block-13 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-14 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .div-block-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .columns-31 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-16 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-63 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .columns-32 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .column-65 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .column-66 {
    margin-top: 20px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .columns-33 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-block-120 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .text-block-124 {
    margin-right: 10px;
  }

  .tab-link-free-trial {
    display: block;
  }

  .value-prop-columns {
    width: auto;
    max-width: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-block-125 {
    margin-bottom: 10px;
  }

  .list-item-4 {
    margin-bottom: 10px;
  }

  .list-item-5 {
    margin-bottom: 10px;
  }

  .list-item-6 {
    margin-bottom: 10px;
  }

  .list-item-7 {
    margin-bottom: 10px;
  }

  .privacy-insights-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .privacy-detail-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .bold-text-10 {
    color: #262626;
  }

  .columns-39 {
    margin-bottom: 40px;
  }

  .submit-button {
    border: 1px solid #000;
  }

  .column-81 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-84 {
    display: block;
  }

  .column-86 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .text-block-131 {
    color: #4d4d4d;
  }

  .text-block-132 {
    color: #4d4d4d;
  }

  .column-89 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .column-90 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .activity-insights-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-91 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .column-92 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .column-93 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .text-block-76 {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .heading-28 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .submit-button-2 {
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
  }

  .columns-47 {
    margin-bottom: 10px;
  }

  .columns-48 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .div-block-36 {
    max-width: none;
  }

  .div-38 {
    flex-direction: column;
  }

  .button-11:active {
    box-shadow: inset 1px 1px 1px 2px #ecedf1;
  }

  .tabs-menu-4 {
    flex-flow: column;
    align-content: stretch;
    justify-content: center;
    align-items: stretch;
    margin-top: 0;
    display: flex;
    overflow: auto;
  }

  .tab-link-annual.w--current {
    display: block;
  }

  .text-block-206 {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  }

  .tabs-content-4 {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .columns-51 {
    flex-direction: column;
    justify-content: space-between;
  }

  .column-103 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-105 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .columns-53 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
  }

  .columns-54 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .columns-55 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-112 {
    margin-bottom: 20px;
  }

  .column-113 {
    margin-bottom: 20px;
  }

  .div-block-51 {
    flex-direction: column;
  }

  .pi-button-style {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }

  .icon-3 {
    border-radius: 10px;
    background-color: transparent;
  }

  .columns-56 {
    margin-top: 10px;
  }

  .text-block-180 {
    color: #4d4d4d;
  }

  .columns-55 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-112 {
    margin-bottom: 20px;
  }

  .column-113 {
    margin-bottom: 20px;
  }

  .pi-button-style {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }

  .icon-3 {
    border-radius: 10px;
    background-color: transparent;
  }

  .hero-split-2 {
    margin-top: 40px;
  }

  .heading-72,
  .heading-73,
  .link-28,
  .link-29 {
    text-align: center;
  }
}

#w-node-_43b4c9fb-dc42-62f9-4972-2fa9ad654b40-f6d12dd5 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6c6849e7-cd82-b8ee-b181-67aa530fbb6b-f6d12dd5 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af5a-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af5e-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af62-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af66-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af6b-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af6f-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af73-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_20d3ebfe-3c75-de92-7aff-89d74712af77-3f1110e6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_43b4c9fb-dc42-62f9-4972-2fa9ad654b40-aeea5f2c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6c6849e7-cd82-b8ee-b181-67aa530fbb6b-aeea5f2c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@font-face {
  font-family: "Proxima nova alt";
  src: url("../fonts/Proxima-Nova-Alt-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima nova alt";
  src: url("../fonts/Proxima-Nova-Alt-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima nova alt";
  src: url("../fonts/Proxima-Nova-Alt-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proximanova";
  src: url("../fonts/Proxima-Nova-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima nova";
  src: url("../fonts/Proxima-Nova-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima nova";
  src:
    url("../fonts/Proxima-Nova-Extrabold.otf") format("opentype"),
    url("../fonts/Proxima-Nova-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima nova";
  src: url("../fonts/Proxima-Nova-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@-webkit-keyframes wave-text {
  00% {
    transform: translateY(0em);
  }

  60% {
    transform: translateY(-0.6em);
  }

  100% {
    transform: translateY(0em);
  }
}

@keyframes wave-text {
  00% {
    transform: translateY(0em);
  }

  60% {
    transform: translateY(-0.6em);
  }

  100% {
    transform: translateY(0em);
  }
}

.option-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-wrap .option {
  background: white;
  padding: 8px 12px;
  margin: 10px 12px 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
}

.option-wrap .option.selected {
  background: red;
  color: white;
}

/* trial */
.right-to-left-enter {
  transform: translateX(100%);
}

.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.right-to-left-exit {
  transform: translateX(0);
}

.right-to-left-exit-active {
  transform: translateX(-100%);
  transition: all 1s ease;
}

.left-to-right-enter {
  transform: translateX(-100%);
}

.left-to-right-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.left-to-right-exit {
  transform: translateX(0);
}

.left-to-right-exit-active {
  transform: translateX(100%);
  transition: all 1s ease;
}

/* .row{
  display: flex;
  padding: 30px;
} */

.refresh-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
