.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.socialLoginButtonWrapper {
  margin: 0 auto;
}

.socialLoginButtonWrapper .googleLoginButton {
  display: block;
  cursor: pointer;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.socialLoginButtonWrapper .googleLoginButton .button.googleSignIn {
  position: relative;
  width: 100%;
  text-decoration: none;
}

.socialLoginButtonWrapper .facebookLoginButton {
  display: block;
  cursor: pointer;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.socialLoginButtonWrapper .facebookLoginButton .button.facebookSignIn {
  position: relative;
  width: 100%;
  text-decoration: none;
}

.card {
  position: relative;
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
}

.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}

.pay-button:disabled:hover {
  box-shadow: none;
}

.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.w-input-field {
  /* display: block; */
  /* width: 100%; */
  height: 38px;
  padding: 8px 12px;
  /* margin-bottom: 10px; */
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.centerAlign {
  text-align: center;
}
/* #selected_bubble {
  display: flex;
  justify-content: center;
} */

.show {
  display: block;
}

.hide {
  display: none;
}

.button-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95px;
  height: 95px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 2px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 16px;
}

.button-large:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.waveTextAnimatedDashboard {
  margin-top: 0em;
  font-size: 18px;
  text-align: center;
}

.waveTextAnimatedDashboard span {
  display: inline-block;
  -webkit-animation: wave-text 1s ease-in-out infinite;
  animation: wave-text 1s ease-in-out infinite;
}

.waveTextAnimatedDashboard span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.waveTextAnimatedDashboard span:nth-of-type(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.waveTextAnimatedDashboard span:nth-of-type(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.waveTextAnimatedDashboard span:nth-of-type(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.waveTextAnimatedDashboard span:nth-of-type(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.spinner-main {
  margin-left: 0%;
  margin-top: -20px;
}

.dashbaord-timeout {
  margin-left: 0%;
  margin-top: -20px;
  margin-left: 5%;
  text-align: center;
  font-size: 20px;
  /* width: 200px; */
  /* padding: 2px; */
  /* background-color: #ffdede; */
}

.appStatus {
  margin-top: 0.3em;
  font-size: small;
  background-color: #faf8f8;
}

.appName {
  font-size: medium;
  margin-top: 0.3em;
  font-weight: 600;
}

.waveTextAnimated {
  margin-top: 0.3em;
  font-size: 15px;
  text-align: center;
}

.waveTextAnimated span {
  display: inline-block;
  -webkit-animation: wave-text 1s ease-in-out infinite;
  animation: wave-text 1s ease-in-out infinite;
}

.waveTextAnimated span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.waveTextAnimated span:nth-of-type(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.waveTextAnimated span:nth-of-type(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.waveTextAnimated span:nth-of-type(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.waveTextAnimated span:nth-of-type(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.waveTextAnimatedDashboard {
  margin-top: 0em;
  font-size: 18px;
  text-align: center;
}

.waveTextAnimatedDashboard span {
  display: inline-block;
  -webkit-animation: wave-text 1s ease-in-out infinite;
  animation: wave-text 1s ease-in-out infinite;
}

.waveTextAnimatedDashboard span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.waveTextAnimatedDashboard {
  margin-top: 0em;
  font-size: 18px;
  text-align: center;
}

.waveTextAnimatedDashboard span {
  display: inline-block;
  -webkit-animation: wave-text 1s ease-in-out infinite;
  animation: wave-text 1s ease-in-out infinite;
}

.waveTextAnimatedDashboard span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.waveTextAnimatedDashboard span:nth-of-type(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.waveTextAnimatedDashboard span:nth-of-type(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.waveTextAnimatedDashboard span:nth-of-type(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.waveTextAnimatedDashboard span:nth-of-type(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.columns-49-tos-myaccount {
  display: block;
  margin-top: 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  font-size: 14px;
  margin-left: 300px;
}

.link-24-tos-myaccount {
  margin-top: 20px;
  margin-left: 100px;
  color: #262626;
}

.link-25-tos-myaccount {
  margin-top: 10px;
  color: #262626;
}

.profile-vals {
  text-decoration: underline;
  font-style: italic;
}

.prc-dotGroup {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.carousel__dot {
  height: 15px;
  width: 15px;
  margin: 3px;
  background-color: #8b8484;
  border-radius: 50%;
  /* border: 1px solid black; */
}

.carousel__dot--selected {
  background-color: rgb(253, 249, 249);
}

.button-large-reconnect {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95px;
  height: 95px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ecedf1;
  box-shadow: 3px 2px 3px 3px #cecbcb;
  color: #b50000;
  font-size: 16px;
}

.button-large-reconnect:hover {
  box-shadow: 1px 1px 1px 2px #262626;
}

.div-block-46-reconnect {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.button-20-block-domain {
  min-height: auto;
  margin-top: 20px;
  min-width: 100px;
  border-radius: 10px;
  background-color: #fc9c02;
  /* box-shadow: 3px 3px 3px 3px #cecbcb; */
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.button-21-block-domain {
  min-height: auto;
  margin-top: 20px;
  min-width: 100px;
  border-radius: 10px;
  background-color: #dddddd;
  /* box-shadow: 3px 3px 3px 3px #cecbcb; */
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.button-9-bd {
  width: 45px;
  height: 45px;
  margin-top: 10px;
  border-radius: 50px;
  margin-right: 25px;
  background-color: #ecedf1;
  background-image: url("./images/key.png");
  background-position: 50% 50%;
  background-size: 75% 75%;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  color: #262626;
}

.button-9-bd-txt {
  margin-top: 38px;
  margin-left: -20px;
}

.button-9-bd-cnl {
  width: 50px;
  height: 48px;
  margin-top: 10px;
  margin-left: 25px;
  border-radius: 50px;
  background-color: #ecedf1;
  background-image: url("./images/cancel.png");
  background-position: 50% 50%;
  background-size: 75% 75%;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 4px 3px #cecbcb;
  color: #262626;
}

.button-9-bd-cnl-txt {
  margin-top: 38px;
  margin-left: -10px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.button-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.bd-onhover {
  margin-top: 25px;
}

.w-form-submitted-bd {
  margin-top: 35px;
}

.text-block-42-bw-lastAccessed {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.text-block-42-blockDomain {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

.text-field-bw-lastaccessed {
  vertical-align: middle;
  margin-right: 10px;
  font-size: 16px;
}

.bw-last-accessed {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: rgb(51, 51, 51);
  width: auto;
  vertical-align: middle;
  max-height: 50px;
  overflow: auto;
  margin-top: 10px;
  width: 50%;
  margin-bottom: 10px;
  border-radius: 0px;
}

.bw-dpdwn {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: rgb(51, 51, 51);
  width: auto;
  vertical-align: middle;
  max-height: 50px;
  overflow: auto;
  margin-top: 10px;
  width: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 0px;
}

.option1-bw {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left;
}

.option2-bw {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left;
}

.option3-bw {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left;
}

.list-7 {
  margin-top: 10px;
  margin-bottom: 60px;
  padding-top: 0px;
  font-size: 18px;
  text-align: left;
  list-style-type: square;
}

.div-block-48-confirm {
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox; */
  display: flex;
  margin-bottom: 40px;
  /* -webkit-box-orient: row;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row; */
  flex-direction: row;
  /* -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center; */
  justify-content: center;
  /* -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center; */
  align-items: center;
}

.button-20-confirm {
  min-height: auto;
  margin-top: 20px;
  min-width: 100px;
  border-radius: 10px;
  margin-left: 10px;
  /* background-color: #07a501; */
  background-color: #dddddd;
  /* box-shadow: 3px 3px 3px 3px #cecbcb; */
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
  color: #262626;
  font-size: 16px;
  text-align: center;
}

.button-21-confirm {
  min-height: auto;
  margin-top: 20px;
  margin-right: 10px;
  min-width: 100px;
  border-radius: 10px;
  /* background-color: #b50000; */
  background-color: #fc9c02;
  /* box-shadow: 3px 3px 3px 3px #cecbcb; */
  box-shadow:
    -2px -2px 1px #fff,
    2px 2px 1px #000;
  color: #262626;
  font-size: 16px;
  text-align: center;
}
