/* -----Common----------------------------- */
.actions {
  display: flex;
  justify-content: center;
  width: 100%;
  /* gap: 2%;  */
  align-items: center;
}

.vertical-divider {
  width: 2px;
  height: 100px;
  background-color: #fc9c02;
}

.horizontal-divider {
  height: 1px;
  background-color: #fc9c02;
}

.custom-card {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

/* -----Top-Buttons----------------------------- */

.toprow-button {
  display: flex;
  width: 150px;
  height: 40px;
  color: #4d4d4d;
  text-align: center;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  box-shadow:
    2px 2px 1px #000,
    -2px -2px 1px #fff;
  line-height: 1;
}

.toprow-button:hover {
  background-color: #fc9c02;
  box-shadow: 1px 1px 1px 2px #262626;
}

/* .refresh-button {
  width: 150px;
  height: 40px;
  color: #4d4d4d;
  text-align: center;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  box-shadow:
    2px 2px 1px #000,
    -2px -2px 1px #fff;
} */

/* .style-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
} */

.text-update {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  margin: 20px; /* this doesnt work for small screens */
}
.text-error {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 16px;
  color: red;
  justify-content: center;
  text-align: center;
  margin: 20px; /* this doesnt work for small screens */
}
/*.yesterday-button {
   width: 150px;
  height: 40px;
  color: #4d4d4d;
  text-align: center;
  text-transform: uppercase;
  background-color: #ecedf1;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  box-shadow:
    2px 2px 1px #000,
    -2px -2px 1px #fff; 
}*/

/* .refresh-button:hover {
  background-color: #fc9c02;
  box-shadow: 1px 1px 1px 2px #262626;
} */

/* .style-button {
  display: flex;
  justify-content: flex-end;
} */

@media only screen and (max-width: 900px) {
  .toprow-button {
    width: 130px;
    height: 30px;
    /* padding-top: 5px; */
    font-size: 14px;
    margin-top: 0; /* more aadjusting here for small screens */
  }
  /* .refresh-button {
    width: 120px;
    height: 30px;
    padding-top: 5px;
    font-size: 14px;
  }
  .yesterday-button {
    width: 120px;
    height: 30px;
    padding-top: 5px;
    font-size: 14px;
  } */
  .text-block-139 {
    margin: 5px;
    margin-top: -15px;
  }
}

/* -----First row Charts----------------------------- */
.chart-container {
  display: flex;
  flex-direction: column;
  /* justify-items: center; */
  justify-content: space-between;
  align-items: center;
}
.donut-container {
  width: 95%;
  height: 250px;
  padding: 5px;
  margin-left: 15px;
  /* color: #58595b; */
}
.bubble-container {
  text-align: center;
}

.bubbles {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
}

.expand-icon-bubble {
  color: #262626;
  align-self: flex-end;
  margin-top: -20px;
}

.chart {
  width: 80%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Is this needed? */
/* div#selected_bubble {
  display: flex;
  flex-direction: row;
  justify-content: center;
} */

/* this class is styled in-line, nit sure why it wont work in the css file? */
/* .tooltip {
  display: "flex";
  flex-direction: "column";
  justify-self: "center";
  align-items: "center";
  opacity: "0";
  background-color: "#ddd";
  border-radius: "5px";
  padding: "10px";
  margin-top: "10px";
  margin-bottom: "5px";
  color: "black";
  height: "150px";
} */

.chart-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.donut-title {
  margin-bottom: -15px;
}

.chart-title {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.chart-subtitle {
  font-size: 12px;
  margin: 0;
}

.malicious-alert {
  color: #b50000;
  display: flex;
  align-self: flex-end;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: -3.5rem;
}

.chart-legend {
  display: flex;
  flex-direction: row;
  margin-top: -15px;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.box-legend {
  width: 20px;
  height: 8px;
  margin-right: 7px;
  margin-top: 4px;
}
.normal-risk {
  background-color: #1e7c1e;
}
.elevated-risk {
  background-color: rgb(255, 140, 0);
}
.high-risk {
  background-color: #b50000;
}
.unique-blocked {
  background-color: #fc9b01;
}
.malicious-blocked {
  background-color: #b50001;
}
.adult-blocked {
  background-color: #702962;
}

.text-lable {
  font-size: 0.75em;
  display: flex;
  justify-content: flex-start;
  text-align: start;
}

/* -----Second row Tables----------------------------- */
.button-div {
  width: 33%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button-div-title {
  font-size: 0.8em;
  font-weight: bold;
  height: 30px;
  padding: 0 12px;
}

@media screen and (max-width: 520px) {
  .button-div-title {
    height: 50px;
  }
}

.numbers-data {
  font-size: 1.2em;
  font-weight: bold;
  /* color: #262626; */
}

.expand-icon {
  display: flex;
  justify-content: flex-end;
}

.left,
.middle,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

.blocked-card {
  margin: 10px;
}

.cells-div-left {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  height: 100px;
  padding: 0 5px;
}

.cells-div-middle-card {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  height: 100px;
  padding: 0 8px;
}

.cells-div-right-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  height: 100px;
  padding: 0 5px;
}

/* -----Third row Actions----------------------------- */

.custom-card-title {
  font-size: 1.2em;
  font-weight: bold;
  /* color: #58595b; */
  margin-bottom: 10px;
}

.third-row {
  padding: 20px 0;
}

.button-div-icon {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 55px;
  height: 55px;
  background-color: #ecedf1;
  cursor: pointer;
}
.disabled {
  pointer-events: none;
  opacity: 0.7;
  box-shadow: none;
}

.button-div-icon:hover {
  /* background-color: #f6f6f9; */
  background-color: #fc9c02;
}

.button-div-icon:active {
  transform: translate(0.5px, 1px);
  box-shadow: 2px -3px 2px 1px #ffffff;
}

.image-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  max-width: none;
  padding: 5px;
}

.switch-label {
  display: flex;
  font-size: 0.6em;
  font-weight: semibold;
  color: #000000;
  margin-top: -15px;
  font-weight: bold;
  gap: 50px
}

@media screen and (max-width: 520px) {
  .switch-label {
    gap: 8px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1050px) {
  .switch-label {
    gap: 8px;
  }
}
@media screen and (min-width: 1051px) and (max-width: 1107px) {
  .switch-label {
    gap: 8px;
  }
}
.switch-icon {
  margin-bottom: 15px;
}

.pageload-spinner-small, .block-unblock-label {  
  width: 50px;
  height: 15px;
}

.pageload-spinner-small {
  padding-top: 2px;
}

/* --------Between the rows for Light and Trial Users----------------------- */

.showUpgradeText {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.hrstyle {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(54, 49, 49, 0.527);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
}
